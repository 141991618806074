import request from '@/utils/request/index.js'
export const getSingleChart = data => { // 获取设备详情单线图
  return request({
    url: '/data/api.Devices/singleLineChart',
    method: 'POST',
    data
  })
}
export const getOverallChart = data => { // 获取设备详情总折线图
  return request({
    url: '/data/api.Devices/allLineChart',
    method: 'POST',
    data
  })
}
export const getHeatMap = data => { // 获取热力图数据
  return request({
    url: '/data/api.Devices/mapList',
    method: 'POST',
    data
  })
}
