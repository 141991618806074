import { computed } from 'vue';
import { useStore } from 'vuex';
export default {
  name: 'ProFooter',
  setup: function setup() {
    var store = useStore();
    var copyright = computed(function () {
      var _store$state$device$c;

      return ((_store$state$device$c = store.state.device.config) === null || _store$state$device$c === void 0 ? void 0 : _store$state$device$c.copyright) || '';
    });
    return {
      copyright: copyright
    };
  }
};