import "core-js/modules/es.function.name.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import { ref, inject, computed, onMounted, watchEffect } from 'vue';
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

var getDeviceList = function getDeviceList() {
  // 获取设备列表
  var $api = inject('$api');
  var store = useStore(); // const data = await $api.getDeviceList()

  var deviceList = computed(function () {
    return store.state.device.deviceList;
  });
  $api.getDeviceList().then(function (data) {
    var list = data.list,
        config = data.config;
    store.commit('device/updateDeviceList', list);
    store.commit('device/updateConfig', config);
  });
  return {
    deviceList: deviceList
  };
};

var changeDevice = function changeDevice(route) {
  // 改变选择的设备
  var router = useRouter(); // console.log('routerrouter', route.query)

  var currentID = ref(route.query.id || null);

  var routerLink = function routerLink(id) {
    // 页面跳转
    if (route.name === 'deviceDetail') {
      currentID.value = id;
      router.replace({
        path: '/deviceDetail',
        query: {
          id: id
        }
      });
    } else {
      // const { href } = router.resolve({
      //   path: '/deviceDetail',
      //   query: { id }
      // })
      // window.open(href, '_blank')
      router.push({
        path: '/deviceDetail',
        query: {
          id: id
        }
      });
    }
  };

  return {
    routerLink: routerLink,
    currentID: currentID
  };
};

export default {
  name: 'DeviceList',
  setup: function setup() {
    var route = useRoute();

    var _getDeviceList = getDeviceList(),
        deviceList = _getDeviceList.deviceList;

    var _changeDevice = changeDevice(route),
        routerLink = _changeDevice.routerLink,
        currentID = _changeDevice.currentID;

    onMounted(function () {
      if (currentID.value) {
        // 页面渲染完成之后，滚动到对应的设备位置
        document.getElementById("device_".concat(currentID.value)).scrollIntoView(true);
      }
    });
    watchEffect(function () {
      currentID.value = route.query.id;
    });
    return {
      deviceList: deviceList,
      routerLink: routerLink,
      route: route,
      currentID: currentID
    };
  }
};