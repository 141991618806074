// 用户模块
export default {
  namespaced: true,
  state () {
    return {
      deviceList: [], // 设备列表
      config: null // 基础配置
    }
  },
  mutations: {
    updateDeviceList (state, payload) { // 更新设备列表
      state.deviceList = payload
    },
    updateConfig (state, payload) { // 更新基础配置
      state.config = payload
    }
  }
}
