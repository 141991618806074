export default {
  name: 'FormInput',
  props: {
    placeholder: {
      // 表单项的 placeholder
      type: String,
      default: ''
    },
    iconfont: {
      // 表单项的图标
      type: String,
      default: ''
    },
    inputType: {
      // 表单项的 type
      type: String,
      default: 'text'
    },
    modelValue: {
      // 表单项的值
      type: String,
      default: ''
    },
    name: {
      // 表单项的键名
      type: String,
      default: ''
    },
    label: {
      // 表单项的 label
      type: String,
      default: ''
    }
  },
  setup: function setup(props, ctx) {
    var changeValue = function changeValue(value) {
      ctx.emit('update:modelValue', value);
    };

    return {
      changeValue: changeValue
    };
  }
};