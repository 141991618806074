import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue";
var _hoisted_1 = {
  class: "app_container"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_ProHeader = _resolveComponent("ProHeader");

  var _component_router_view = _resolveComponent("router-view");

  var _component_ProFooter = _resolveComponent("ProFooter");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_ProHeader), _createVNode(_component_router_view), _createVNode(_component_ProFooter)]);
}