import { computed } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import { ElMessageBox } from 'element-plus';

var routerLink = function routerLink() {
  // 路由跳转
  var router = useRouter();

  var innerLink = function innerLink(path) {
    // 页面跳转
    router.push({
      path: path
    });
  };

  var outerLink = function outerLink() {
    window.location.href = 'http://www.tcair.cn/';
  };

  return {
    innerLink: innerLink,
    outerLink: outerLink
  };
};

export default {
  name: 'ProHeader',
  setup: function setup() {
    var store = useStore();

    var _routerLink = routerLink(),
        innerLink = _routerLink.innerLink,
        outerLink = _routerLink.outerLink;

    var isLogin = computed(function () {
      return store.state.user.profile.token;
    });

    var logout = function logout() {
      // 退出登录
      ElMessageBox.confirm('退出后需要重新登录，是否继续', '提示', {
        confirmButtonText: '继续',
        cancelButtonText: '再想想',
        type: 'warning'
      }).then(function () {
        // 确认退出登录
        store.commit('device/updateDeviceList', []);
        store.commit('user/updateUserInfo', {});
        innerLink('/login');
      }).catch(function () {});
    };

    return {
      innerLink: innerLink,
      outerLink: outerLink,
      logout: logout,
      isLogin: isLogin
    };
  }
};