// 自动化注册全局组件
import * as Icons from '@element-plus/icons-vue'
export default {
  install (app) {
    //  全局注册components目录下的组件
    //  获取所有.vue结尾文件,通过.keys()可以获取到每一个的路径
    const ctx = require.context('./', true, /\.vue$/)
    ctx.keys().forEach(item => {
      //  ctx同时也是函数，可以接收一个参数(路径)
      const component = ctx(item).default
      app.component(component.name, component)
    })
    // 全局注册 element-plus 的 Icons 组件
    Object.keys(Icons).forEach(key => {
      app.component(key, Icons[key])
    })
  }
}
