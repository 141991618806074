import ScaleLoader from 'vue-spinner/src/ScaleLoader.vue';
export default {
  name: 'Spinner',
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    ScaleLoader: ScaleLoader
  },
  setup: function setup() {}
};