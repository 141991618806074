import request from '@/utils/request/index.js'
export const userLogin = data => { // 登录
  return request({
    url: '/data/api.Users/login',
    method: 'POST',
    data
  })
}
export const updatePassword = data => { // 修改密码
  return request({
    url: '/data/api.Users/updatePassword',
    method: 'POST',
    data
  })
}
// export const getSystemConfig = () => { // 获取系统配置
//   return request({
//     url: '/data/api.Devices/get?group=systems',
//     method: 'GET'
//   })
// }
