import "core-js/modules/es.function.name.js";
import { normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createSlots as _createSlots, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue";
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_el_input = _resolveComponent("el-input");

  var _component_el_form_item = _resolveComponent("el-form-item");

  return _openBlock(), _createBlock(_component_el_form_item, {
    prop: $props.name,
    label: $props.label
  }, {
    default: _withCtx(function () {
      return [_createVNode(_component_el_input, {
        placeholder: $props.placeholder,
        type: $props.inputType,
        onInput: $setup.changeValue,
        modelValue: $props.modelValue
      }, _createSlots({
        _: 2
      }, [$props.iconfont ? {
        name: "prefix",
        fn: _withCtx(function () {
          return [_createElementVNode("span", {
            class: _normalizeClass(['iconfont', $props.iconfont])
          }, null, 2)];
        })
      } : undefined]), 1032, ["placeholder", "type", "onInput", "modelValue"])];
    }),
    _: 1
  }, 8, ["prop", "label"]);
}