import "core-js/modules/es.function.name.js";
import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-7b822dd2"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "container flex"
};
var _hoisted_2 = {
  key: 0,
  class: "list"
};
var _hoisted_3 = ["onClick", "id"];
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_router_view = _resolveComponent("router-view");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [$setup.deviceList.length ? (_openBlock(), _createElementBlock("div", _hoisted_2, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($setup.deviceList, function (item) {
    return _openBlock(), _createElementBlock("div", {
      class: _normalizeClass(['item ellipsis-1', item.id == $setup.currentID ? 'active' : '']),
      onClick: function onClick($event) {
        return $setup.routerLink(item.id);
      },
      key: item.id,
      id: 'device_' + item.id
    }, _toDisplayString(item.name), 11, _hoisted_3);
  }), 128))])) : _createCommentVNode("", true), (_openBlock(), _createBlock(_component_router_view, {
    key: $setup.route.fullPath
  }))]);
}