import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import api from '@/modules/index.js'
import 'normalize.css'
import store from './store'
import Components from '@/components/install.js'
// 没有按需加载的组件对应的样式文件需要手动引入
import 'element-plus/es/components/message/style/css'
import 'element-plus/es/components/message-box/style/css'
const app = createApp(App)
app.provide('$api', api)
app.use(router).use(store).use(Components).mount('#app')
