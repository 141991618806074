import request from '@/utils/request/index.js'
export const getDeviceList = () => { // 获取设备列表
  return request({
    url: '/data/api.Devices/getList',
    method: 'POST'
  })
}
export const getDeviceInfo = data => { // 获取设备详情
  return request({
    url: '/data/api.Devices/read',
    method: 'POST',
    data
  })
}
export const getDeviceFieldList = () => { // 获取设备参数列表
  return request({
    url: '/data/api.Devices/fieldList',
    method: 'POST'
  })
}
export const exportDeviceData = data => { // 导出设备数据
  return request({
    url: '/data/api.Devices/export',
    method: 'POST',
    data
  })
}
