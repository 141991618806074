import { createRouter, createWebHashHistory } from 'vue-router'
import Layout from '@/layout'
import DeviceList from '@/components/deviceList'
import store from '@/store'
const routes = [
  {
    path: '/',
    component: Layout,
    children: [
      {
        path: '/login',
        name: 'login',
        component: () => import(/* webpackChunkName: "login" */ '../views/login'),
        meta: {
          title: '登录'
        }
      },
      {
        path: '/updatePassword',
        name: 'updatePassword',
        component: () => import(/* webpackChunkName: "updatePassword" */ '../views/updatePassword'),
        meta: {
          title: '修改密码'
        }
      },
      {
        path: '/',
        component: DeviceList,
        children: [
          {
            path: '/',
            name: 'home',
            component: () => import(/* webpackChunkName: "home" */ '../views/home'),
            meta: {
              title: '首页'
            }
          },
          {
            path: '/heatMap',
            name: 'heatMap',
            component: () => import(/* webpackChunkName: "heatMap" */ '../views/heatMap'),
            meta: {
              title: '热力图'
            }
          },
          // {
          //   path: '/test',
          //   component: () => import(/* webpackChunkName: "test" */ '../views/test')
          // },
          {
            path: '/deviceDetail',
            name: 'deviceDetail',
            component: () => import(/* webpackChunkName: "deviceDetail" */ '../views/deviceDetail'),
            meta: {
              title: '设备详情'
            }
          }
        ]
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

router.beforeEach((to, from) => {
  if (to.meta.title) { // 判断是否有标题
    document.title = to.meta.title
  }
  const { token } = store.state.user.profile
  if (token && to.name === 'login') {
    if (!from.name) { // 新的窗口，直接访问登录页
      router.replace({
        path: '/'
      })
    }
    return false
  }
  // console.log('from', from.name)
  // console.log('to', to.name)
  // if ((!from.name && to.name === 'home') && !token) {
  //   router.push({ path: '/login' })
  // }
  if (!token && to.name === 'forgetPassword') {
    return false
  }
})

export default router
