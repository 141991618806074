import {
  createStore
} from 'vuex'
import createPersistedstate from 'vuex-persistedstate'
import user from './modules/user'
import device from './modules/device'
export default createStore({
  modules: {
    user,
    device
  },
  plugins: [
    createPersistedstate({
      key: 'echarts-store',
      paths: ['user.profile', 'device.deviceList']
    })
  ]
})
