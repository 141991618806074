import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";

var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-72329564"), n = n(), _popScopeId(), n;
};

var _hoisted_1 = {
  class: "spinner"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_scale_loader = _resolveComponent("scale-loader");

  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createVNode(_component_scale_loader, {
    loading: $props.loading,
    color: "#0095EA"
  }, null, 8, ["loading"])]);
}